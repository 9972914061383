<template>
  <div class="onDemand bgc_ffffff">
    <div id="playerAll" v-show="option.vid" v-if="is_all">
      <div id="waterMark">
        {{ user.RealName }}
        {{ user.Id }}
      </div>
    </div>
    <div class="width_1200 cont" v-if="!is_all">
      <div class="cont_left">
        <img
          v-lazy="course.CoverImg"
          v-show="!option.vid"
          alt
          width="850"
          height="500"
        />
        <div id="player" v-show="option.vid">
          <div id="waterMark">
            {{ user.RealName }}
            {{ user.Id }}
          </div>
        </div>

        <div class="title">
          <img v-lazy="course.CoverImg" class="cover" alt />
          <div class="rig">
            <div class="rig_top">
              <span>点播</span>
              <span>{{ course.CourseName }}</span>
            </div>
            <div class="rig_bottom">讲师：{{ course.TeacherName }}</div>
          </div>
        </div>
      </div>
      <div class="cont_right">
        <div class="title">{{ course.CourseName }}</div>
        <el-scrollbar class="pl_scrollbar" style="height: 529px">
          <!--<div v-for="(lis, i) in list" :key="i" class="live_list">
            <p class="bgc_FEFCFC">{{ lis.MenuName }}</p>
            <div v-for="(li, j) in lis.children" :key="j" @click="vidClick(li)">
              <p :class="li.Id == index ? 'c_4481FE' : 'c_666666'">
                <span>{{ li.MenuName }}</span>
                <span class="c_FA9722">
                  {{
                    li.HasVideo == true ? "免费" : ""
                  }}
                </span>
                <span>{{ li.per }}</span>
              </p>
            </div>
          </div>-->
          <el-tree
            ref="elTree"
            :indent="5"
            default-expand-all
            highlight-current
            node-key="Id"
            :default-checked-keys="checkedKeys"
            :expand-on-click-node="false"
            :data="list"
            :props="defaultProps"
            @node-click="handleNodeClick"
          >
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="node.label"
                placement="right"
              >
                <p :class="data.show == true ? 'c_4481FE' : 'c_666666'">
                  <span>{{ node.label }}</span>
                  <span
                    class="c_FA9722"
                    v-if="data.VideoNum != null && data.VideoNum != ''"
                  >
                    <!-- {{ data.HasVideo == true ? "已授权" : "" }} -->
                  </span>
                  <span v-if="data.VideoNum != null && data.VideoNum != ''">
                    {{
                      data.VideoDuration == 0 || data.VideoDuration == null
                        ? "0.00%"
                        : (
                            (data.NowProcess / data.VideoDuration) *
                            100
                          ).toFixed(2) + "%"
                    }}
                  </span>
                  <span v-if="data.RuleCategory == 0" style="margin-left: 10px">
                  </span>
                  <span
                    v-else-if="
                      data.RuleCategory == 1 &&
                      data.VideoNum != null &&
                      data.VideoNum != ''
                    "
                    style="margin-left: 10px"
                    class="c_FA9722"
                  >
                    {{ data.SurplusCount }}/{{ data.TotalCount }}
                  </span>
                  <span
                    v-else-if="data.RuleCategory == 2"
                    style="margin-left: 10px"
                    class="c_FA9722"
                  >
                    {{ data.ValidityEnd | dataYMD }} 止
                  </span>
                  <span
                    v-else-if="data.RuleCategory == 3"
                    style="margin-left: 10px"
                    class="c_FA9722"
                  >
                    <span style="margin-right: 10px"
                      >{{ data.SurplusCount }}/{{ data.TotalCount }}</span
                    >
                    {{ data.ValidityEnd | dataYMD }} 止
                  </span>
                </p>
              </el-tooltip>
            </div>
          </el-tree>
        </el-scrollbar>
      </div>
    </div>
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
  </div>
</template>
<script>
import {
  courseQueryVideoList,
  liveQueryVideoToken,
  videoUpdateVideoProgress,
  checkValid,
} from "@/api/personal";
import { courseQueryCourseDetail } from "@/api/course";
import {
  studentCompareFace,
  sysConfigQueryFaceSetting,
  querySystemConfig,
} from "@/api/api";
import camera from "@/components/canvasCamera.vue";
import moment from "moment"; //导入模块
export default {
  data() {
    return {
      is_all: false,
      allCategory: 0,
      is_Full: false,
      defaultProps: {
        label: "MenuName",
        children: "children",
      },
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      vid: "", //后台返回的视频ID
      playsafe: "", //播放加密视频需要请求加密字符串
      ts: "", //加密视频需传入的13位毫秒级时间戳
      sign: "", //加密视频所需的签名
      timer: "",
      timerOne: "",
      timerTwo: "",
      list: [],
      course: {},
      index: "",
      user: {},
      option: {
        vid: "",
        // playsafe:"",
        // ts:"",
        // sign:"",
      },
      category: 2, //1直播2点播
      rule: {},
      dialogVisible: false,
      checkedKeys: [],
      thisNowProcess: 0,
      handleObj: {},
      handleO: 0,
      isCharge: true,
      courseId: 0,
      courseInfor: {},
      kfRate: 100,
      processRandomNum: "",
      isJump: 0,
      JumpTime: 0,
      isFirst: 1,
      nowVideoDuration: 1,
      isComplete: 0,
      fullscreen: true,
      windowHeight: "1080px",
      windowWidth: "1920px",
      videoEndFlg: 95,
      watchStartTime: 0, //播放开始时间
      ban_seek: "on", //on 进度条是否允许拖动
      ban_preview_video: "off", //为on时鼠标在悬浮进度条时不显示预览缩略图
      isEnd: 0,
    };
  },
  components: {
    camera,
  },
  watch: {
    courseInfor(val, oldVal) {
      if (val != null) {
        if (this.courseInfor.IsCharge === false) {
          this.postCourseQueryVideoList();
          this.postSysConfigQueryFaceSetting();
        } else {
          //收费判断是否登录
          if (localStorage.getItem("userInfo")) {
            this.user = JSON.parse(this.$store.state.userInfo);
            this.postCourseQueryVideoList();
            this.postSysConfigQueryFaceSetting();
          } else {
            this.$confirm("收费课程请先登录?", "提示", {
              confirmButtonText: "去登录",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/login");
              })
              .catch(() => {});
            return false;
          }
        }
      }
    },
  },
  created() {
    let that = this;
    window.onresize = function () {
      if (!that.checkFull()) {
        // 退出全屏后要执行的动作
        console.log("退出全屏");
        that.fullscreen = false;
        that.exitScreen();
      }
    };
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  mounted() {
    this.courseId = this.$route.query.CoursId;
    this.queryCourseDetai();
    this.queryConfig();
    this.queryConfigEndStandard();
    window.addEventListener("keydown", (event) => {
      if (
        event.keyCode === 123 ||
        event.keyCode === 122 ||
        event.keyCode === 121 ||
        event.keyCode === 120 ||
        event.keyCode === 119 ||
        event.keyCode === 118 ||
        event.keyCode === 117 ||
        event.keyCode === 116 ||
        event.keyCode === 115 ||
        event.keyCode === 114 ||
        event.keyCode === 113 ||
        event.keyCode === 112
      ) {
        event.preventDefault();
      }
      if (event.keyCode === 27) {
        console.log("27");
        event.preventDefault();
      }
    });
    this.windowHeight = window.screen.height + "px";
    this.windowWidth = window.screen.width + "px";

    // if (window.history && window.history.pushState) {
    //   // 向历史记录中插入了当前页
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", this.goBack, false);
    // }

    //   var that = this;
    //   // 监听浏览器是否关闭
    //   window.addEventListener("beforeunload", (e) => that.browerStatus());

    //   let _this = this;
    //   window.onbeforeunload = function (e) {
    //     console.log(_this.$route.name);
    //     if (_this.$route.name == "onDemand") {
    //       e = e || window.event;
    //       // 兼容IE8和Firefox 4之前的版本
    //       if (e) {
    //         e.returnValue = "关闭提示1111";
    //       }
    //       // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    //       return "关闭提示222";
    //     } else {
    //       window.onbeforeunload = null;
    //     }
    //   };
    // },
    // 使用路由守卫判断是否离开当前页面
    // beforeRouteLeave(to, form, next) {
    //   this.$confirm("当前信息未保存，离开页面将会放弃所有修改数据，", "提示", {
    //     closeOnClickModal: false,
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       // 点击确定则往下执行
    //       next();
    //     })
    //     .catch(() => {
    //       // 取消则关闭弹窗不执行
    //     });
  },
  methods: {
    goBack() {
      this.$confirm("当前有未完成的订单，确认退出支付吗？ ", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        // callback: (action) => {
        //   return false;
        // },
      })
        .then(() => {
          // debugger;
          history.go(-1);
          // this.$router.go(-1)
        })
        .catch(() => {
          // window.history.pushState(null, null, document.URL);
        });
    },
    // 监听浏览器是否关闭
    browerStatus() {
      this.$prompt("当前有未完成的订单，确认退出支付吗？ ", "温馨提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          return false;
        },
      });
    },
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    checkFull() {
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    //查询点播计次标准
    async queryConfig() {
      const res = await querySystemConfig("00001");
      if (res.success == true) {
        this.kfRate = res.response.Value;
      }
    },
    async queryConfigEndStandard() {
      const res = await querySystemConfig("00013");
      if (res.success == true) {
        this.videoEndFlg = res.response.Value;
      }
    },
    async queryCourseDetai() {
      const res = await courseQueryCourseDetail(this.courseId);
      if (res.success === true) {
        this.courseInfor = res.response;
        this.isCharge = res.response.IsCharge;
      } else {
        this.$message.error(res.msg);
      }
    },
    async handleNodeClick(obj) {
      this.processRandomNum = parseInt(new Date().getTime());
      let nowProcessRate = ((obj.NowProcess / obj.VideoDuration) * 100).toFixed(
        2
      );
      // this.option.vid = obj.VideoNum;
      // console.log(this.option.vid);
      this.watchStartTime = obj.NowProcess;
      this.isEnd = 0;
      if (this.isCharge == "true" || this.isCharge == true) {
        //视频规则判断
        if (obj.RuleCategory == 0) {
          //不限
          this.ban_seek = "off";
          this.ban_preview_video = "off";
          this.watchStartTime = obj.NowProcess;
          this.dialogVisible = true;
          this.handleObj = obj;
          this.handleO = 1;
        } else if (obj.RuleCategory == 1) {
          //次数
          if (obj.SurplusCount <= 0) {
            //没有次数需要判断当前进度是否是在视频结束点之后

            if (Number(nowProcessRate) < Number(this.videoEndFlg)) {
              this.$confirm(
                "当前所选视频已达到计次标准，可观看计次标准以后内容, 是否继续?",
                "温馨提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  this.watchStartTime = obj.VideoDuration * (this.kfRate / 100);
                  console.log(this.watchStartTime);
                  // this.watchStartTime = 7300;
                  this.ban_seek = "on";
                  this.ban_preview_video = "on";

                  this.dialogVisible = true;
                  this.handleObj = obj;
                  this.handleO = 1;

                  //测试时关闭人脸
                  // this.handlePlay(this.handleObj);
                })
                .catch(() => {
                  return false;
                });
            } else {
              //如果当前进度已经大于了视频结束点，则提示
              this.$alert("您的播放次数已用完，谢谢观看！ ", "温馨提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  return false;
                },
              });
            }
          } else {
            this.ban_seek = "off";
            this.ban_preview_video = "off";
            this.watchStartTime = obj.NowProcess;
            this.dialogVisible = true;
            this.handleObj = obj;
            this.handleO = 1;
            //测试时关闭人脸
            // this.handlePlay(this.handleObj);
          }
          //按次数
        } else if (obj.RuleCategory == 2) {
          //时间
          // 获取当前系统时间戳
          // let time = parseInt(
          //   new Date(moment().format("YYYY-MM-DD") + " 00:00:00").getTime() /
          //     1000
          // );

          // // 有效时间转时间戳
          // let dateEnd = Math.round(new Date(obj.ValidityEnd).getTime() / 1000);

          // let dateStart = Math.round(
          //   new Date(obj.ValidityStart).getTime() / 1000
          // );
          // if (dateEnd < time || dateStart > time) {
          //   this.$message.error("【" + obj.MenuName + "】未在有效时间内");
          //   return false;
          // }

          const flag = await checkValid({
            CourseId: this.courseId,
            StudentVideoId: obj.Id,
          });
          console.log(flag);
          if (flag.response == false) {
            this.$message.error(flag.msg);
            return false;
          } else {
            this.ban_seek = "off";
            this.ban_preview_video = "off";
            this.watchStartTime = obj.NowProcess;
            this.dialogVisible = true;
            this.handleObj = obj;
            this.handleO = 1;
            //测试时关闭人脸
            // this.handlePlay(this.handleObj);
          }
        } else if (obj.RuleCategory == 3) {
          //次数+时间

          // 获取当前系统时间戳
          // let time = parseInt(
          //   new Date(moment().format("YYYY-MM-DD") + " 00:00:00").getTime() /
          //     1000
          // );
          // // 有效时间转时间戳
          // let dateEnd = Math.round(new Date(obj.ValidityEnd).getTime() / 1000);
          // let dateStart = Math.round(
          //   new Date(obj.ValidityStart).getTime() / 1000
          // );
          // if (dateEnd < time || dateStart > time) {
          //   this.$message.error("【" + obj.MenuName + "】未在有效时间内");
          //   return false;
          // }

          const flag = await checkValid({
            CourseId: this.courseId,
            StudentVideoId: obj.Id,
          });
          console.log(flag);
          if (flag.response == false) {
            this.$message.error(flag.msg);
            return false;
          } else if (obj.SurplusCount <= 0) {
            // this.$message.error(
            //   "【" + obj.MenuName + "】您的播放次数已用完，谢谢观看！ "
            // );
            // return false;

            if (Number(nowProcessRate) < Number(this.videoEndFlg)) {
              this.$confirm(
                "当前所选视频已达到计次标准，可观看计次标准以后内容, 是否继续?",
                "温馨提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  this.watchStartTime = obj.VideoDuration * (this.kfRate / 100);
                  console.log(this.watchStartTime);
                  // this.watchStartTime = 7300;
                  this.ban_seek = "on";
                  this.ban_preview_video = "on";

                  this.dialogVisible = true;
                  this.handleObj = obj;
                  this.handleO = 1;

                  //测试时关闭人脸
                  // this.handlePlay(this.handleObj);
                })
                .catch(() => {
                  return false;
                });
            } else {
              this.$alert("您的播放次数已用完，谢谢观看！ ", "温馨提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  return false;
                },
              });
            }
          } else {
            this.ban_seek = "off";
            this.ban_preview_video = "off";
            this.watchStartTime = obj.NowProcess;
            this.dialogVisible = true;
            this.handleObj = obj;
            this.handleO = 1;
            //测试时关闭人脸
            // this.handlePlay(this.handleObj);
          }
        } else {
          //没有设置规则
          this.ban_seek = "off";
          this.ban_preview_video = "off";
          this.watchStartTime = obj.NowProcess;
          this.dialogVisible = true;
          this.handleObj = obj;
          this.handleO = 1;
        }
      } else {
        //不进行人脸是
        this.handleObj = obj;
        this.handleO = 1;
        this.handlePlay(this.handleObj);
      }
    },
    handlePlay(obj) {
      console.log(this.player);

      this.handleO = 0;
      if (obj.VideoNum == null || obj.VideoNum == "") {
        this.$message.error("该节点未关联视频，请选择其他节点观看");
        return;
      }
      if (obj.HasVideo) {
        this.vid = obj.VideoNum;
        this.thisNowProcess = obj.NowProcess;
        this.nowVideoDuration = obj.VideoDuration;
        this.isComplete = 0;
        this.getLiveQueryVideoToken();
        this.getForEach(this.list, obj);

        //提交jindu
        this.isJump = 1;
        this.JumpTime = obj.NowProcess;
        this.postVideoUpdateVideoProgress();
      } else {
        this.$message.error("你没有该章节点播课程的权限");
        return;
      }
    },
    getForEach(data, obj) {
      if (data.length > 0) {
        data.forEach((item) => {
          if (item.children === undefined) {
            this.$set(item, "children", []);
          }

          if (item.VideoNum != obj.VideoNum) {
            this.$set(item, "show", false);
            this.getForEach(item.children, obj);
          } else {
            this.$set(item, "show", true);
          }
        });
      }
    },
    getClose() {
      this.dialogVisible = false;
    },
    // 人脸识别
    async postStudentCompareFace(flag) {
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      if (flag == true) {
        // this.$message.success(res.msg);
        this.dialogVisible = false;
        if (this.handleO == 1) this.handlePlay(this.handleObj);
      } else {
        this.$message.error(
          "人脸识别失败，请重试！识别时请保证面部出现在识别框内"
        );
        this.dialogVisible = false;
        // setTimeout(() => {
        //   this.$router.push({ path: "/personal/myCourse" });
        // }, 1500);
      }
    },
    // 人脸识别规则
    async postSysConfigQueryFaceSetting() {
      const res = await sysConfigQueryFaceSetting(this.category);
      if (res.success == true) {
        this.rule = res.response[0];
        if (this.rule == null) {
          return;
        }
        if (this.rule.RuleType == 1) {
          this.dialogVisible = false;
        } else if (this.rule.RuleType == 2) {
          let num =
            parseInt(this.rule.TimeInterval) / parseInt(this.rule.ShowCount);
          this.timerTwo = setInterval(() => {
            this.dialogVisible = true;
          }, num * 1000 * 60);
        } else if (this.rule.RuleType == 3) {
          let num,
            min = parseInt(this.rule.MinRandomTime),
            max = parseInt(this.rule.MaxRandomTime);
          num = this.randomNumber(min, max);
          this.timerTwo = setInterval(() => {
            num = this.randomNumber(min, max);
            // console.log(num, num * 1000)
            this.dialogVisible = true;
          }, num * 1000 * 60);
        }
        // console.log(res.response[0])
      } else {
        this.$message.error(res.msg);
      }
    },
    //随机数
    randomNumber(min, max) {
      return Math.floor(Math.random() * (min - max) + max);
    },
    //跑马灯
    genarateMark() {
      let playerDOM = document.getElementById("player"),
        playerWidth = playerDOM?.clientWidth,
        playerHeight = playerDOM?.clientHeight;
      // 添加 50px 偏差以免定位错乱
      let positionX = this.common.getRandom(0, playerWidth - 50),
        positionY = this.common.getRandom(0, playerHeight - 50);

      const markDOM = document.getElementById("waterMark");
      markDOM.style.left = positionX + "px";
      markDOM.style.top = positionY + "px";
    },
    // 切换视频
    vidClick(obj) {
      this.index = obj.Id;
      this.vid = obj.VideoNum;
      this.getLiveQueryVideoToken();
      // const option = {
      //   vid: obj.VideoNum,
      // };
    },
    //点播进度 每隔10s调用移除
    async postVideoUpdateVideoProgress() {
      if (this.vid == null || this.vid == "") {
        return;
      }
      let obj = Object.entries(
        JSON.parse(localStorage.getItem("polyvPlayerStorage"))
      );
      console.log(obj);
      let startTime = "startTime-" + this.vid;
      let NowTime = 0;
      if (this.isJump == 1) {
        NowTime = this.JumpTime;
        this.isJump = 0;
      } else {
        obj.forEach((item) => {
          if (item[0] == startTime) {
            NowTime = item[1];
          }
        });
      }

      this.getTreeNode(this.vid, this.list, NowTime, 0);
      let parm = {
        VideoNum: this.vid,
        NowTime: NowTime,
        RandomNum: this.processRandomNum, //计费随机数
        IsEnd: this.isEnd,
      };
      const res = await videoUpdateVideoProgress(parm);
      if (res.success == true) {
        this.isFirst = 0;
        this.$alert(res.msg, "温馨提示", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });

        this.getTreeNode(this.vid, this.list, NowTime, 1);
        // this.postCourseQueryVideoList();
      }
    },
    //点播解密
    async getLiveQueryVideoToken(val) {
      const res = await liveQueryVideoToken(this.vid);
      if (res.success == true) {
        this.playsafe = res.response.token;
        this.ts = res.response.createdTime;
        this.sign = res.response.sign;

        if (val == 1 || this.player == null) {
          this.loadPlayerScript(this.loadPlayer);
        } else {
          //切换视频
          this.option.vid = this.vid;
          this.option.playsafe = this.playsafe;
          this.option.ts = this.ts;
          this.option.sign = this.sign;
          this.option.ban_preview_video = this.ban_preview_video;
          this.option.ban_seek = this.ban_seek;
          this.option.watchStartTime = this.watchStartTime;
          console.log(this.option);
          this.player.changeVid(this.option);
        }
      }
    },
    //判断课程或视频是否有效
    async postCheckValid(params) {
      const res = await checkValid(params);
      return res;
    },
    getPackage(arr, obj) {
      if (arr !== undefined) {
        arr.forEach((item, i) => {
          obj.forEach((it, j) => {
            if (item.Id === it.ParentId) {
              if (item.children === undefined) {
                this.$set(item, "children", []);
              }
              item.children.push(it);
            }
          });
          this.getPackage(item.children, obj);
        });
      }
    },
    getSome(data) {
      if (data.length > 0) {
        var some = data.some((obj) => {
          // some  一真即真
          if (obj.children === undefined) {
            this.$set(obj, "children", []);
          }
          if (
            obj.VideoNum == null ||
            obj.VideoNum == undefined ||
            obj.VideoNum == ""
          ) {
            this.getSome(obj.children);
          } else {
            this.$refs.elTree.setCheckedKeys([obj.Id]);
            this.checkedKeys.push(obj.Id);
            this.$set(obj, "show", true);
            this.vid = obj.VideoNum;
            this.getLiveQueryVideoToken(1);
            return true;
          }
        });
      }
    },
    getTreeNode(arr, data, time, iskc) {
      if (typeof data === "object") {
        for (let j = 0; data[j] !== undefined; j++) {
          if (arr === data[j].VideoNum) {
            if (this.isEnd == 1) {
              data[j].NowProcess = data[j].VideoDuration;
            } else {
              data[j].NowProcess = time;
            }
            if (iskc == 1) {
              if (data[j].SurplusCount != 0) {
                data[j].SurplusCount = data[j].SurplusCount - 1;
              }
            }
          }
        }
        for (let i = 0; data[i] !== undefined; i++) {
          if (data[i].children.length > 0) {
            this.getTreeNode(arr, data[i].children, time, iskc);
          }
        }
      }
    },
    //列表
    async postCourseQueryVideoList() {
      this.course = this.$route.query;
      let courseId = this.$route.query.CoursId;
      const res = await courseQueryVideoList(courseId);
      if (res.success == true) {
        let obj = res.response ? res.response : [];
        if (obj.length > 0) {
          let arr = obj.filter((lis) => {
            if (lis.ParentId == 0) {
              this.$set(lis, "children", []);
              return lis;
            }
          });
          this.list = arr;
          this.getPackage(this.list, obj);

          if (this.isFirst == 1) {
            this.loadPlayerScript(this.loadPlayer);
          }
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    //引入保利威点播js
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      let that = this;

      const polyvPlayer = window.polyvPlayer;

      this.player = polyvPlayer({
        // wrap: this.allCategory == 1 ? "#playerAll" : "#player",
        wrap: "#player",
        width: 850,
        height: 500,
        watchStartTime: this.watchStartTime, //播放开始时间，表示视频从第几秒开始播放，参数值需小于视频时长。
        vid: this.vid,
        playsafe: this.playsafe,
        ts: this.ts,
        sign: this.sign,
        fullscreenProxy: true,
        hideRepeat: false, //是否隐藏播放结束后的重播按钮
        // full_page_screen: true,
        // allowFullscreen:false  //是否允许全屏
        ban_seek: this.ban_seek, //是否禁止拖拽进度条
        ban_history_time: "off", //当值为’on‘时会禁用续播功能。
        ban_preview_video: this.ban_preview_video, //为on时鼠标在悬浮进度条时不显示预览缩略图
      });
      this.timer = setInterval(() => {
        if (this.courseInfor.IsCharge == false) {
          console.log("www");
        } else {
          //收费的更新进度
          this.postVideoUpdateVideoProgress();
        }
      }, 20 * 1000);

      this.timerOne = setInterval(() => {
        this.genarateMark();
      }, 3 * 1000);

      this.player.on("s2j_onPlayOver", (vid) => {
        let nowVideo = this.list[0].children.filter(function (item) {
          return item.VideoNum == vid;
        });

        console.log(nowVideo[0].SurplusCount);

        if (nowVideo[0].SurplusCount <= 0 && nowVideo[0].RuleCategory!=0) {
          this.isEnd = 1;

          this.player.destroy();
          this.player = null;
          this.vid = "";

          this.option.vid = "";

          this.loadPlayerScript(this.loadPlayer);

          clearInterval(this.timer);
          clearInterval(this.timerOne);
          clearInterval(this.timerTwo);
        } else {
          this.isEnd = 0;
        }

        // this.loadPlayerScript(this.loadPlayer);

        this.processRandomNum = parseInt(new Date().getTime());
        this.postVideoUpdateVideoProgress();
        // this.$message.error("视频播放完毕");
      });

      this.player.on("s2j_onVideoSeek", (start, end, week) => {
        this.isJump = 1;
        this.JumpTime = parseInt(end);
        this.postVideoUpdateVideoProgress();
      });
      window.onFullscreenProxy = function (vid, toFullscreen) {
        if (!that.is_Full) {
          that.fullScreen();
        } else {
          that.exitScreen();
        }
      };
      // this.player.on("toggleFullscreen", (vid, toFullscreen) => {
      //   console.log(vid);
      //   console.log(toFullscreen);
      // });
    },
    //全屏
    fullScreen() {
      var el = document.querySelector("#player");
      var rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullScreen;
      //typeof rfs != "undefined" && rfs
      if (rfs) {
        rfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        //for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
        var wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
      let aa = document.getElementsByClassName("pv-video-player")[0];
      aa.style.cssText = `width: ${this.windowWidth} !important;height: ${this.windowHeight} !important;`;
      this.is_Full = true;
      this.handleFullScreen();
    },
    //退出全屏
    exitScreen() {
      var el = document;
      var cfs =
        el.cancelFullScreen ||
        el.webkitCancelFullScreen ||
        el.mozCancelFullScreen ||
        el.exitFullScreen;
      //typeof cfs != "undefined" && cfs
      if (cfs) {
        cfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        //for IE，这里和fullScreen相同，模拟按下F11键退出全屏
        var wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
      let aa = document.getElementsByClassName("pv-video-player")[0];
      aa.style.cssText = "width: 850px !important;height: 500px !important;";
      this.is_Full = false;
      this.handleFullScreen();
    },
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
    clearInterval(this.timer);
    clearInterval(this.timerOne);
    clearInterval(this.timerTwo);
    window.removeEventListener("keydown", (event) => {
      event.preventDefault();
    });
  },
};
</script>
<style lang="less" scoped>
.onDemand {
  // margin-top: -30px;
  .cont {
    color: #ffffff;
    padding: 70px 0;
    display: flex;
    justify-content: space-between;
    min-height: 600px;
    .cont_left {
      background-color: #2d2d2d;
      width: 850px;
      height: 600px;
      #player {
        // width: 850px !important;
        // height: 500px !important;
        position: relative;
        // /deep/ .pv-video-player {
        //   width: 850px !important;
        //   height: 500px !important;
        // }
        #waterMark {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 999;
          // color: #1890ff;
          color: #6e6e6e;
          display: inline-block;
          font-weight: bold;
        }
      }
      .title {
        height: 100px;
        display: flex;
        align-items: center;
        .cover {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin: 0 20px;
        }
        .rig {
          font-size: 14px;
          .rig_top {
            display: flex;
            align-items: center;
            span {
              height: 24px;
              line-height: 24px;
              display: inline-block;
              &:nth-child(1) {
                width: 44px;
                text-align: center;
                background: #575757;
                border-radius: 4px;
              }
              &:nth-child(2) {
                font-size: 22px;
                margin-left: 20px;
              }
            }
          }
          .rig_bottom {
            margin-top: 20px;
            line-height: 100%;
          }
        }
      }
    }
    .cont_right {
      width: 350px;
      height: 600px;
      background-color: #131313;
      // padding: 0 20px;
      color: #d8d8d8;
      font-size: 16px;
      .title {
        text-indent: 0;
        margin: 28px 0 22px;
      }
      .live_list {
        font-size: 14px;
        text-indent: 14px;
        line-height: 32px;
        p {
          margin: 20px 0;
          cursor: pointer;
          span {
            &:nth-child(2) {
              margin-left: 8px;
            }
            &:nth-child(3) {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}
.el-tree {
  background: none;
  .custom-tree-node {
    color: #666666;
  }
}
/deep/.el-tree-node:focus > .el-tree-node__content {
  background-color: transparent !important;
  .el-tooltip {
    color: #4481fe !important;
  }
}
/deep/.el-tree-node__content:hover {
  background-color: transparent;
  .el-tooltip {
    color: #4481fe !important;
  }
}

/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: transparent !important;
}
// 隐藏从头播放
/deep/.pv-start-time {
  display: none !important;
}
</style>
