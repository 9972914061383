var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"onDemand bgc_ffffff"},[(_vm.is_all)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.option.vid),expression:"option.vid"}],attrs:{"id":"playerAll"}},[_c('div',{attrs:{"id":"waterMark"}},[_vm._v(" "+_vm._s(_vm.user.RealName)+" "+_vm._s(_vm.user.Id)+" ")])]):_vm._e(),(!_vm.is_all)?_c('div',{staticClass:"width_1200 cont"},[_c('div',{staticClass:"cont_left"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.course.CoverImg),expression:"course.CoverImg"},{name:"show",rawName:"v-show",value:(!_vm.option.vid),expression:"!option.vid"}],attrs:{"alt":"","width":"850","height":"500"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.option.vid),expression:"option.vid"}],attrs:{"id":"player"}},[_c('div',{attrs:{"id":"waterMark"}},[_vm._v(" "+_vm._s(_vm.user.RealName)+" "+_vm._s(_vm.user.Id)+" ")])]),_c('div',{staticClass:"title"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.course.CoverImg),expression:"course.CoverImg"}],staticClass:"cover",attrs:{"alt":""}}),_c('div',{staticClass:"rig"},[_c('div',{staticClass:"rig_top"},[_c('span',[_vm._v("点播")]),_c('span',[_vm._v(_vm._s(_vm.course.CourseName))])]),_c('div',{staticClass:"rig_bottom"},[_vm._v("讲师："+_vm._s(_vm.course.TeacherName))])])])]),_c('div',{staticClass:"cont_right"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.course.CourseName))]),_c('el-scrollbar',{staticClass:"pl_scrollbar",staticStyle:{"height":"529px"}},[_c('el-tree',{ref:"elTree",attrs:{"indent":5,"default-expand-all":"","highlight-current":"","node-key":"Id","default-checked-keys":_vm.checkedKeys,"expand-on-click-node":false,"data":_vm.list,"props":_vm.defaultProps},on:{"node-click":_vm.handleNodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{staticClass:"custom-tree-node"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":node.label,"placement":"right"}},[_c('p',{class:data.show == true ? 'c_4481FE' : 'c_666666'},[_c('span',[_vm._v(_vm._s(node.label))]),(data.VideoNum != null && data.VideoNum != '')?_c('span',{staticClass:"c_FA9722"}):_vm._e(),(data.VideoNum != null && data.VideoNum != '')?_c('span',[_vm._v(" "+_vm._s(data.VideoDuration == 0 || data.VideoDuration == null ? "0.00%" : ( (data.NowProcess / data.VideoDuration) * 100 ).toFixed(2) + "%")+" ")]):_vm._e(),(data.RuleCategory == 0)?_c('span',{staticStyle:{"margin-left":"10px"}}):(
                    data.RuleCategory == 1 &&
                    data.VideoNum != null &&
                    data.VideoNum != ''
                  )?_c('span',{staticClass:"c_FA9722",staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(data.SurplusCount)+"/"+_vm._s(data.TotalCount)+" ")]):(data.RuleCategory == 2)?_c('span',{staticClass:"c_FA9722",staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm._f("dataYMD")(data.ValidityEnd))+" 止 ")]):(data.RuleCategory == 3)?_c('span',{staticClass:"c_FA9722",staticStyle:{"margin-left":"10px"}},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(data.SurplusCount)+"/"+_vm._s(data.TotalCount))]),_vm._v(" "+_vm._s(_vm._f("dataYMD")(data.ValidityEnd))+" 止 ")]):_vm._e()])])],1)}}],null,false,4138294967)})],1)],1)]):_vm._e(),(_vm.dialogVisible)?_c('camera',{attrs:{"dialogVisible":_vm.dialogVisible},on:{"close":function($event){return _vm.getClose()},"studentface":_vm.postStudentCompareFace}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }